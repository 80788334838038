/*----------------------------------------*/
/*    04 - Layout - Header
/*----------------------------------------*/
/*-- Main Header Area --*/
.main-header-area{
    .header-logo{
        img{
            width: 125px;
        }
    }
}
/* ---Sticky Header--- */
.header-sticky{
    &.header-transparent{
        position: fixed;
        width: 100%;
        z-index: 99;
        @include breakpoint(max-sm_device){
            position: relative;
        }
    }
    &.sticky {
        animation: 800ms ease-in-out 0s normal none 1 running slideInDown;
        left: 0;
        position: fixed;
        background-color: $white-color;
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
        right: 0;
        top: 0;
        transition: .3s;
        z-index: 99;
    }
}
/* ---Main Menu--- */
.main-nav{
    > ul{
        > li{
            display: inline-block;
            position: relative;
            padding: 30px 0;
            &:hover {
                .dropdown-hover {
                    visibility: visible;
                    opacity: 1;
                    top: 100%;
                }
                .dropdown-hover-2 {
                    visibility: visible;
                    opacity: 1;
                    top: 100%;
                }
            }
            > a{
                display: block;
                padding: 8px 16px;
                font-size: 15px;
                text-transform: capitalize;
                font-family: $body-font-family-name;
                font-weight: 600;
                line-height: 18px;
                color: $black-color;
                &.active{
                    color: $primary-color;
                }
                &:hover{
                    color: $primary-color;
                }
                i{
                    margin-left: 0px;
                    font-size: 15px;
                    vertical-align: middle;
                }
                .menu-text{
                    position: relative;
                }
            }
        }
    }
}
/*--- Dropdwon ---*/
.dropdown-submenu{
    background: $white-color;
    padding: 20px 0 24px;
    width: 230px;
    box-shadow: 0 0px 3px rgba(0, 0, 0, 0.3);
    border-bottom: 3px solid $primary-color;
    > li {
        > a {
            padding: 10px 20px;
            display: flex;
            justify-content: space-between;
            text-transform: capitalize;
            font-family: $body-font-family-name;
            line-height: 18px;
            position: relative;
            color: $black-color;
            font-size: 14px;
            font-weight: 400;
            &:hover{
                color: $primary-color;
            }
            &.active{
                color: $primary-color;
            }
            > i {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 2%;
                font-size: 15px;
                padding-right: 15px;
            }
        }
    }
}
.dropdown-hover{
    position: absolute;
    top: 110%;
    opacity: 0;
    visibility: hidden;
    transition: all .3s linear 0.2s;
    z-index: 999;
}
.dropdown-hover-2{
    position: absolute;
    top: 110%;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: all .3s linear 0.2s;
    z-index: 999;
}
/*--- Mega-Menu ---*/
.mega-menu{
    background: $white-color;
    width: 700px;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px 0;
    box-shadow: 0 0px 3px rgba(0, 0, 0, 0.3);
    border-bottom: 3px solid $primary-color;
    @include breakpoint(max-lg_device){
        left: 100%;
    }
    .menu-colum{
        width: 33.333333%;
        padding: 10px;
        float: left;
        ul{
            li{
                .mega-menu-text{
                    position: relative;
                    font-size: 18px;
                    font-family: $body-font-family-name;
                    display: table-caption;
                    padding: 0px 20px 0px 0px;
                    margin: 0px 0px 10px 20px;
                    &:after{
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        margin: auto; 
                        width: 100%;
                        border-bottom: 2px solid $primary-color;
                        content: "";
                        z-index: 1;
                    }
                }
                a{
                    padding: 10px 20px;
                    display: block;
                    font-family: $body-font-family-name;
                    line-height: 20px;
                    color: $black-color;
                    font-size: 14px;
                    font-weight: 400;
                    &:hover{
                        color: $primary-color;
                    }
                    &.active{
                        color: $primary-color;
                    }
                }
            }
        }
    }
}
/*--- Header Right Area ---*/
.dropdown-search{
    width: 340px;
    box-shadow: 0 0px 3px rgba(0, 0, 0, 0.3);
    background-color: $white-color;
    @include breakpoint(max-xxs_device){
        width: 280px;
    }
    form{
        input{
            width: 100%;
            display: block;
            padding: 10px 30px 10px 10px;
            border: 0px;
        }
        button{
            position: absolute;
            top: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            padding: 10px;
            &:hover{
                color: $primary-color;
            }
        }
    }
}
.header-right-area{
    &.main-nav {
        display: flex;
        justify-content: flex-end;
        > ul{
            position: relative;
            > li{
                position: static;
                display: flex;
                align-items: center;
                > span{
                    font-size: 15px;
                    font-weight: 600;
                    position: relative;
                    padding-left: 15px;
                    margin-left: 15px;
                    &:before{
                        left: 0;
                        top: 50%;
                        width: 1px;
                        height: 12px;
                        content: "";
                        position: absolute;
                        background-color: $black-color;
                        transform: translateY(-50%);
                    }
                    &:first-child{
                        padding: 0px;
                        margin: 0px;
                        &:before{
                            display: none;
                        }
                    }
                }
                > a{
                    padding: 8px 0px 7px;
                    > i{
                        font-size: 18px;
                        line-height: 1;
                        color: $black-color;
                        &:hover{
                            color: $primary-color;
                        }
                    }
                }
                &.account-menu-wrap{
                    a{
                        &.active{
                            color: $primary-color;
                        }
                    }
                }
                &.sidemenu-wrap{
                    margin-right: 30px;
                }
                &.minicart-wrap{
                    margin-right: 30px;
                    .minicart-btn{
                        position: relative;
                        i{
                            font-size: 18px;
                            line-height: 1;
                            color: $default-color;
                            &:hover{
                                color: $primary-color;
                            }
                        }
                        .cart-item_count{
                            background-color: $primary-color;
                            border-radius: 50%;
                            color: $white-color;
                            display: block;
                            font-size: 12px;
                            text-align: center;
                            line-height: 18px;
                            height: 18px;
                            width: 18px;
                            position: absolute;
                            right: -10px;
                            top: -5px;
                        }
                    }
                }
                &.mobile-menu-btn{
                    a{
                        i{
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
    .cart-item-wrapper{
        padding: 25px 20px;
        width: 350px;
        box-shadow: 0 0px 3px rgba(0, 0, 0, 0.3);
        background-color: $white-color;
        @include breakpoint(max-xxs_device){
            width: 280px;
        }
        .single-cart-item{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .cart-img{
                border: 1px solid rgba(0, 0, 0, 0.07);
                margin-right: 15px;
                flex-basis: 80px;
                width: 80px;
            }
            .cart-text{
                flex-basis: calc(100% - 95px);
                width: calc(100% - 95px);
                .title{
                    font-size: 15px;
                    font-weight: 600;
                    margin-top: 0;
                    margin-bottom: 8px;
                    font-family: $body-font-family-name;
                }
                .cart-text-btn{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .cart-qty{
                        font-size: 15px;
                        font-weight: 600;
                        color: #303030;
                    }
                    .cart-price{
                        font-size: 15px;
                        font-weight: 600;
                        color: $primary-color;
                    }
                    button{
                        font-size: 18px;
                        color: #000;
                        transition: .3s;
                        &:hover{
                            color: $primary-color;
                        }
                    }
                }
            }
        }
        .cart-price-total{
            font-size: 20px;
            padding: 20px 0;
            border-top: 1px solid #d8d8d8;
            border-bottom: 1px solid #d8d8d8;
            margin: 20px 0;
        }
        .cart-links{
            a:not(:last-child){
                margin-right: 5px;
            }
        }
    }
}
/*----- Offcanvas Mobile Menu start -----*/
.off-canvas-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s;
    z-index: 9999;
    &.open{
        opacity: 1;
        visibility: visible;
        .off-canvas-inner-content {
            transform: none;
        }
    }
    .off-canvas-overlay {
        background-color: rgba(0, 0, 0, 0.4);
        content: '';
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
    .off-canvas-inner-content {
        background-color: $white-color;
        width: 380px;
        height: 100%;
        position: relative;
        transform: translateX(calc(-100% - 50px));
        transition: 0.4s;
        @include breakpoint(max-xxs_device){
            width: 280px;
        }
    }
    .btn-close-off-canvas {
        left: 92%;
        position: relative;
        @include breakpoint(max-xxs_device){
            left: 89.2%;
        }
    }
    .off-canvas-inner {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        height: 100%;
        overflow: auto;
    }
}
/*----- Offcanvas Menu start -----*/
.off-canvas-menu-wrapper{
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s;
    z-index: 9999;
    &.open{
        opacity: 1;
        visibility: visible;
        .off-canvas-inner-content {
            transform: none;
        }
    }
    .off-canvas-overlay {
        background-color: rgba(0, 0, 0, 0.4);
        content: '';
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
    .off-canvas-inner-content {
        background-color: $white-color;
        width: 400px;
        height: 100%;
        position: relative;
        transform: translateX(calc(100% - 50px));
        transition: 0.4s;
        left: auto;
        right: 0;
        position: fixed;
    }
    .off-canvas-inner {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        height: 100%;
        overflow: auto;
    }
    .offcanvas-widget-area{
        padding-top: 40px;
        .desc-content{
            padding-bottom: 30px;
        }
    }
    .top-info-wrap{
        padding-bottom: 20px;
    }
}
.btn-close-off-canvas {
    width: 30px;
    height: 30px;
    color: $black-color;
    line-height: 30px;
    cursor: pointer;
    transition: 0.4s;
    i {
        transform: rotate(0);
        transition: 0.4s;
        font-size: 20px;
        line-height: 20px;
        align-items: center;
        text-align: center;
        display: flex;
        position: relative;
        overflow: hidden;
        padding: 5px;
        justify-content: center;
    }
    &:hover{
        color: $primary-color;
        transition: 0.4s;
        i {
            transform: rotate(-90deg);
        }
    } 
}
.top-info-wrap{
    bottom: 0px;
    margin-top: auto;
    padding-bottom: 40px;
    .address-info{
        margin-bottom: 20px;
        li{
            margin-bottom: 10px;
            i{
                font-size: 18px;
                margin-right: 10px;
            }
        }
    }
}
.offcanvas-widget-area{
    padding: 0px 40px 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    @include breakpoint(max-xxs_device){
        padding: 0 30px 20px;
    }
    .menu-top-menu > li {
        list-style: none;
        margin-bottom: 20px;
        white-space: nowrap;
        > a {
            font-size: 60px;
            padding-bottom: 8px;
            color: $default-color;
            line-height: 25px;
            font-family: $different-font-style-2;
            &:hover {
                color: $primary-color;
            }
        }
    }
    .switcher {
        border-top: 1px solid $border-color-2;
        margin-top: 0px;
        padding-top: 30px;
        margin-bottom: 40px;
    }
    .language {
        margin-bottom: 8px;
    }
    .switcher-title {
        display: inline-block;
        margin-right: 10px;
        text-transform: uppercase;
        vertical-align: middle;
    }
    .switcher-menu {
        background: none;
        display: inline-block;
        > ul > li {
            list-style: none;
            position: relative;
            > a {
                background: none;
                color: $primary-color;
                cursor: pointer;
                font-size: inherit;
                font-weight: normal;
                padding: 5px 15px 5px 0;
                position: relative;
                vertical-align: middle;
                &:before {
                    content: '\f107';
                    font-family: FontAwesome;
                    font-size: 12px;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
    .switcher-dropdown {
        background: $white-color;
        border: 1px solid $border-color-2;
        height: auto;
        left: 50%;
        padding: 6px 10px !important;
        position: absolute;
        top: 100%;
        z-index: 1000;
        opacity: 0;
        visibility: hidden;
        transform: translateX(-50%);
        transition: all .3s linear 0.2s;
    }
    .switcher-menu > ul > li:hover .switcher-dropdown {
        opacity: 1;
        visibility: visible;
    }
    .switcher-dropdown > li {
        list-style: none;
        display: block;
        border-bottom: 0 10px;
        text-align: center;
        border-bottom: 1px solid $border-color-2;
        &:last-child {
            border-bottom: none;
        }
        > a {
            background: none;
            color: $text-color-2;
            display: block;
            line-height: 33px;
            white-space: nowrap;
            &:hover {
                color: $primary-color;
            }
        }
    }
}
/*---------------------------
    - Mobile Menu Css
-----------------------------*/
.search-box-offcanvas {
    margin-bottom: 10px;
    padding: 20px 40px 0px 40px;
    @include breakpoint(max-xxs_device){
        padding: 30px 30px 0 30px;
    }
    & form {
        position: relative;
        & input {
            color: $black-color;
            font-size: 13px;
            width: 100%;
            height: 50px;
            border: none;
            padding: 0 40px 0 10px;
            background-color: $white-color;
            border: 1px solid $border-color;
            &:hover{
                border-color: $primary-color;
            }
        }
        & .search-btn {
            top: 50%;
            right: 1px;
            font-size: 20px;
            color: $black-color;
            position: absolute;
            background: $white-color;
            border: none;
            transform: translateY(-50%);
            padding: 8px;
            &:hover{
                color: $primary-color;
            }
        }
    }
}
.mobile-header .category-toggle {
    padding: 8px 15px;
  }
  
.mobile-menu-btn .off-canvas-btn {
    font-size: 22px;
    cursor: pointer;
    padding: 0px 12px;
    line-height: 1;
    padding-right: 0;
    transition: 0.4s;
    margin-top: -3px;
}
  
.mobile-menu {
    height: 100%;
    padding-right: 30px;
    margin-right: -30px;
    li {
      > a {
        font-size: 15px;
        color: #252525;
        text-transform: capitalize;
        line-height: 18px;
        position: relative;
        display: block;
        padding: 10px 0;
        font-weight: 600;
            &:hover {
                color: #000;
            }   
        }
      ul li {
        border: none;
            a {
            font-size: 14px;
            text-transform: capitalize;
            padding: 10px 15px 8px;
            font-weight: 400;
            }
        }
    }
  
    .has-children, .menu-item-has-children {
        position: relative;
    }
  
    .has-children .menu-expand, .menu-item-has-children .menu-expand {
        line-height: 50;
        top: -7px;
        left: 95%;
        width: 30px;
        position: absolute;
        height: 50px;
        text-align: center;
        cursor: pointer;
        @include breakpoint(max-sm_device){
            left: 90%;
        }
    }
  
    .has-children .menu-expand i, .menu-item-has-children .menu-expand i {
        display: block;
        position: relative;
        width: 10px;
        margin-top: 25px;
        border-bottom: 1px solid #252525;
        transition: all 250ms ease-out;
    }
  
    .has-children .menu-expand i:before, .menu-item-has-children .menu-expand i:before {
        top: 0;
        width: 100%;
        content: "";
        display: block;
        position: absolute;
        transform: rotate(90deg);
        border-bottom: 1px solid #252525;
        transition: 0.4s;
    }
  
    .has-children.active > .menu-expand i:before, .menu-item-has-children.active > .menu-expand i:before {
        transform: rotate(0);
    }
}
.mobile-navigation {
    padding: 30px 40px 30px 40px;
    @include breakpoint(max-xxs_device){
        padding: 30px;
    }
    nav {
        height: 100%;
    }
}