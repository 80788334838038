/*----------------------------------------*/
/*    Blog Scss
/*----------------------------------------*/
.single-blog {
    transition: all 0.3s ease-in-out;
    box-shadow: 1px 1px 10px 1px rgba(209, 209, 209, 0.5);
    &:hover{
        box-shadow: 0px 10px 30px 0px rgba(209, 209, 209, 0.5); 
    }
    .blog-content {
        background: $white-color;
        padding: 25px 30px 40px 30px;
        @include breakpoint(max-md_device){
            padding: 20px;
        }
        @include breakpoint(max-xxs_device){
            padding: 20px 10px
        }
        h4 {
            color: #010101;
            font-size: 22px;
            margin-bottom: 15px;
            @include breakpoint(max-md_device){
                font-size: 20px;
            }
            @include breakpoint(max-xxs_device){
                font-size: 18px;
            }
        }
        .blog-date {
            display: inline-block;
            height: 66px;
            line-height: 24px;
            text-align: center;
            width: 77px;
        }
        
        .blog-post-info {
            display: block;
            margin-bottom: 10px;
            > span {
                color: #7a7a7a;
                display: inline-block;
                font-size: 13px;
                font-weight: 500;
                margin-right: 15px;
                text-transform: capitalize;
                position: relative;
                &:last-child {
                    margin-right: 0;
                }
                a {
                    color: #7a7a7a;
                    &:hover {
                        color: $primary-color;
                    }
                    &:before {
                        background-color: $primary-color;
                        content: "";
                        height: 1px;
                        position: absolute;
                        right: -12px;
                        top: 10px;
                        width: 6px;
                    }
                }
            }
        }
        .blog-text{
            > p {
                font-size: 16px;
                line-height: 26px;
                @include breakpoint(max-md_device){
                    font-size: 14px;
                    line-height: 22px;
                }
                @include breakpoint(max-xxs_device){
                    font-size: 12px;
                }
            }
        } 
        
        .readmore {
            color: #333;
            display: inline-block;
            font-size: 15px;
            i {
                font-size: 14px;
            }
            &:hover {
                color: $primary-color;
            }
        }
    }
}
// Blog List Scss
.blog-list-vertical {
	background: $bg-color-3;
	min-height: 113px;
	padding: 35px 0 35px 166px;
	position: relative;
    transition: all 0.3s ease-in-out;
    @include breakpoint(max-xxs_device){
        padding: 15px 0 15px 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &:hover {
        background: $primary-color;
        color: #fff;
    }
    .post-date {
        background: $primary-color;
        color: #fff;
        display: flex;
        font-size: 11px;
        height: 100%;
        left: 0;
        letter-spacing: 1px;
        padding: 0 15px;
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        top: 0;
        width: 100px;
        align-items: center;
        justify-content: center;
        @include breakpoint(max-xxs_device){
            position: relative;
            background: transparent;
            text-align: left;
            color: $black-color;
            padding: 0;
            display: block;
            margin-bottom: 5px;
        }
    }
    .post-title {
        font-size: 18px;
        a{
            &:hover{
                color: $white-color;
            }
        }
    }
    .post-author {
        @include breakpoint(max-xxs_device){
            margin-bottom: 0;
        }
        img {
            display: inline-block;
            height: 43px;
            left: 115px;
            position: absolute;
            top: 37px;
            width: 43px;
            border-radius: 100%;
            @include breakpoint(max-xxs_device){
                left: 30px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}
.blog-list-vertical:hover .post-author,
.blog-list-vertical:hover .post-title,
.blog-list-vertical:hover .post-author a {
	color: #fff;
}
.latest-post-carousel{
    width: 100%;
    height: 390px;
    overflow: hidden;
    .single-item{
        height: 130px !important;
    }
}
/*-- Blog Details Area Scss --*/
.blog-post-details{
    .blog-post-wrapper{
        .blockquote{
            background-color: $bg-color-3;
            color: $default-color;
            font-size: 16px;
            padding: 40px 40px;
            margin-left: 60px;
            position: relative;
            font-style: italic;
            border-left: 4px solid $primary-color;
            @include breakpoint(max-sm_device){
                margin-left: 30px;
            }
            @include breakpoint(max-xxs_device){
                font-size: 14px;
                margin-left: 20px;
            }
        }
        .share-article{
            text-align: center;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left-side{
                float: left;
            }
            .right-side{
                float: right;
            }
            h6{
                @include breakpoint(max-xxs_device){
                    display: none;
                }
            }
        }
        .social-share{
            display: flex;
            justify-content: center;
            border-top: 1px solid $border-color;
            margin-top: 10px;
            padding-top: 10px;
            a{
                i{
                    font-size: 28px;
                    vertical-align: middle;
                    margin-left: 10px;
                }
                &:first-child{
                    i{
                        margin-left: 0;
                    }
                }
            }
            span{
                font-size: 16px;
                font-weight: 600;
            }
        }
        .comment-area-wrapper{
            .single-comment-wrap{
                &.comment-reply{
                    padding-left: 50px;
                    @include breakpoint(max-xxs_device){
                        padding-left: 20px;
                    }
                }
                .author-thumb{
                    a{
                        width: 70px;
                        height: 70px;
                        margin-right: 10px;
                        img{
                            width: 100%;
                        }
                        @include breakpoint(max-xxs_device){
                            width: 50px;
                            height: 50px;
                        }
                    }
                }
            }
        }
    }
}
.comment-box{
    .input-area{
        padding: 10px;
        &:focus{
            border: 1px solid $primary-color;
        }
    }
}