/*----------------------------------------*/
/*    00 - Base - Variables
/*----------------------------------------*/
//--- All Variables - Related Stuff Included In This Area ---↓
// project specific variables
$theme-color--default: #484848;
$default-color:        #000000; 

// Color
$primary-color:     #E72463;
$text-color:        #484848;
$text-color-2:      #555555;
$heading-color:     #000000;
$theme-black-color: #000000;
$border-color:      #dddddd;
$border-color-2:    #e5e5e5;
$border-color-3:    #eceff8;
$bg-color:          #f8f8f8;
$bg-color-2:        #1b1b1b;
$bg-color-3:        #f6f6f6;


// Additional Color Variable
$white-color:          #ffffff;
$chyan-color:          #b4b4b4;
$black-color:          #000000;
$offwhite-color:       #f8f8ff;

// Social Color Variable
$facebook:  #3B5998;
$twitter:   #55ACEE;
$linkedin:  #2867B2;
$pinterest: #DF0022;
$youtube:   #c4302b;
$vimeo:     #86c9ef;

// Font Family
$body-font-family-name: 'Poppins', sans-serif;
$different-font-style-2: 'Great Vibes', cursive;
$different-font-style: 'Josefin Slab', serif;