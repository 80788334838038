/*----------------------------------------*/
/*    01 - Global - Default
/*----------------------------------------*/

/*-- Google Font --*/
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,100;0,300;0,400;0,600;0,700;1,100;1,300;1,400;1,600;1,700&display=swap');

body {
    line-height: 1.6;
	font-weight: 400;
	color: $text-color;
	font-family: $body-font-family-name;
	scroll-behavior: smooth;
	font-size: 14px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $body-font-family-name;
	color: $heading-color;
	font-weight: 500;
	margin-top: 0px;
	font-weight: 400;
	line-height: 1;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: inherit;
}

h1 {
	font-size: 36px;
}

h2 {
	font-size: 30px;
}

h3 {
	font-size: 24px;
}

h4 {
	font-size: 18px;
}

h5 {
	font-size: 14px;
}

h6 {
	font-size: 12px;
}
p{
	margin-bottom: 10px;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type=number] {
	-moz-appearance: textfield;
}
ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
img {
	max-width: 100%;
}
.img-full {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: block;
}
a{
	color: inherit;
    display: inline-block;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
	&:hover{
		color: $primary-color;
	}
}
a,
button,
i {
	transition: .3s;
}
a,
a:hover,
a:focus,
a:active,
button,
button:focus,
.btn,
.btn:focus,
input,
input:focus,
select,
textarea,
.slick-slide {
	text-decoration: none;
	outline: 0;
}

input{
	&.input-field{
		border: 1px solid $border-color;
	}
}
textarea{
	&.textarea-field{
		border: 1px solid $border-color;
	}
}
input[type="checkbox"] {
	display: none;
}
input[type="checkbox"] + label {
	position: relative;
	padding-left: 30px;
	line-height: 15px;
	font-size: 14px;
	font-weight: 400;
	margin: 0;
	&:before {
		position: absolute;
		left: 0;
		top: 0;
		width: 15px;
		height: 15px;
		display: block;
		border: 1px solid $border-color;
		content: "";
		transition: .3s; 
	}
	&:after {
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		content: "\f00c";
		font-family: 'FontAwesome';
		font-weight: 600;
		font-size: 12px;
		line-height: 15px;
		opacity: 0;
		width: 15px;
		text-align: center;
		transition: .3s;
		color: $primary-color;
	}
}
input[type="checkbox"]:checked + label {
	&:before {
		border: 1px solid $primary-color;
	}
	&:after {
		opacity: 1;
	}
}
button,
.btn {
	cursor: pointer;
	border: 0;
	padding: 0;
	background-color: transparent;
}
hr {
	margin: 60px 0;
	padding: 0px;
	border-bottom: 1px solid $border-color-3;
	border-top: 0px;
}
.mark,
mark {
	background: $primary-color none repeat scroll 0 0;
	color: $white-color;
}
/* ---Font Style--- */
.font-style_italic{
	font-style: italic;
}
/* ---Line Height--- */
.line-height-1{
	line-height: 1;
}