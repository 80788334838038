/*----------------------------------------*/
/*    Testimonial Scss
/*----------------------------------------*/
.testimonial-img {
	display: inline-block;
    margin-bottom: 45px;
    margin-top: 30px;
    position: relative;
    img{
        border-radius: 50%;
        box-shadow: 0px 4px 10px 2px rgba(188, 188, 188, 0.7);
        width: 150px;
        height: 150px;
        @include breakpoint(max-sm_device){
            width: 130px;
            height: 130px;
        }
        @include breakpoint(max-xs_device){
            width: 100px;
            height: 100px;
        }
    }
    @include breakpoint(max-xs_device){
        margin-bottom: 20px;
        margin-top: 10px;
    }
}
.testimonial-content{
    >p {
        font-size: 20px;
        line-height: 32px;
        margin: 0 auto 25px auto;
        max-width: 800px;
        position: relative;
        text-align: center;
        @include breakpoint(max-md_device){
            max-width: 640px;
        }
        @include breakpoint(max-sm_device){
            max-width: 500px;
            font-size: 16px;
            line-height: 28px;
        }
        @include breakpoint(max-xs_device){
            max-width: 400px;
            font-size: 14px;
        }
        @include breakpoint(max-xxs_device){
            max-width: 280px;
            font-size: 13px;
            line-height: 24px;
        }
    }
}
.testimonial-author{
    >h6 {
        color: $primary-color;
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
        >span {
            color: $text-color-2;
            font-size: 14px;
            font-weight: 400;
            position: relative;
            &:before {
                content: "-";
                display: inline-block;
                margin: 0 5px 0 2px;
                color: $text-color-2
            }
        }
    }
}
.testimonial-area-2{
    .single-testimonial{
        margin-bottom: 0px;
        @include breakpoint(max-md_device){
            margin-bottom: 45px;
        }
    }
    .testimonial-img{
        margin-top: 3px;
    }
    .testimonial-content{
        padding: 0 20px;
    }
}