/*----------------------------------------*/
/*    13 - Pages - Checkout
/*----------------------------------------*/
/*--------- Checkout Page Wrapper Start ---------*/
.coupon-accordion {
	h3 {
		background-color: $bg-color;
		font-size: 15px;
		font-weight: 500;
		list-style: outside none none;
		margin: 0 0 30px;
		padding: 16px 32px 16px 20px;
		position: relative;
		width: auto;
		text-transform: none;
		@include breakpoint(max-xxs_device){
			line-height: 24px;
		}
	}
	span {
		cursor: pointer;
		transition: .3s;
		text-transform: none;
	}
	.coupon-content {
		border: 1px solid $border-color;
		margin-bottom: 20px;
		display: none;
		padding: 20px;
		.coupon-info {
			p {
				margin-bottom: 0;
				&.form-row-first,
				&.form-row-last {
					label {
						display: block;
						span {
							&.required {
								color: $primary-color;
							}
						}
					}
					input {
						border: 1px solid $border-color;
						height: 36px;
						margin: 0 0 14px;
						border-radius: 0;
						max-width: 100%;
						padding: 0 10px 0 10px;
						width: 370px;
                        background-color: transparent;
                        &:focus{
                            border: 1px solid $primary-color;
                        }
					}
				}
				&.form-row {
					input[type="submit"] {
						background: $default-color none repeat scroll 0 0;
						border: medium none;
						border-radius: 0;
						box-shadow: none;
						color: $white-color;
						cursor: pointer;
						display: inline-block;
						font-size: 12px;
						height: 40px;
						line-height: 40px;
						margin-right: 15px;
						padding: 0 30px;
						text-shadow: none;
						text-transform: uppercase;
						transition: .3s;
						width: inherit;
					}
					input[type="checkbox"] {
						height: inherit;
						position: relative;
						top: 2px;
						width: inherit;
					}
					label {
						margin-top: 7px;
					}
				}
				&.lost-password {
					margin-top: 15px;
					a{
						font-size: 14px;
					}
				}
			}
		}
	}
}
.coupon-checkout-content {
	margin-bottom: 30px;
	display: none;
	.coupon-info {
		.checkout-coupon {
			margin-bottom: 0;
			input[type="text"] {
				background-color: transparent;
				border: 1px solid $border-color;
				border-radius: 0;
				height: 36px;
				padding-left: 10px;
				padding-right: 10px;
				width: 170px;
			}
			input[type="submit"] {
				background: $default-color;
				border: medium none;
				border-radius: 0;
				color: $white-color;
				height: 36px;
				cursor: pointer;
				margin-left: 6px;
				padding: 5px 10px;
				transition: all 0.3s ease 0s;
				width: inherit;
			}
			.coupon-inner_btn {
				@include breakpoint (max-xxs_device) {
					margin: 28px 0 0 !important;
				}
				&:hover{
					background-color: $primary-color;
				}
			}
		}
	}
}
.checkbox-form {
	h3 {
		border-bottom: 1px solid $border-color;
		font-size: 25px;
		margin: 0 0 25px;
		padding-bottom: 10px;
		text-transform: uppercase;
		width: 100%;
		@include breakpoint (max-xxs_device) {
			font-size: 18px;
		}
	}
	.country-select {
		margin-bottom: 25px;
		.myniceselect {
			&.nice-select {
				span {
					height: 40px;
					line-height: 40px;
					display: inline-block;
					width: 100%;
					padding: 0 0 0 15px;
				}
			}
		}
	}
	.checkout-form-list {
		margin-bottom: 30px;
		input[type="text"],
		input[type="password"],
		input[type="email"] {
			background: $white-color;
			border: 1px solid $border-color;
			border-radius: 0;
			height: 42px;
			width: 100%;
            padding: 0 10px 0 10px;
            &:focus{
                border: 1px solid $primary-color;
            }
		}
		&.create-acc {
			margin-bottom: 15px !important;
			label {
				display: inline-block;
			}
		}
	}
	.country-select,
	&.checkout-form-list {
		label {
			margin: 0 0 5px;
			display: block;
			span {
				&.required {
					display: inline-block;
					font-size: 24px;
					line-height: 16px;
					position: relative;
					top: 5px;
				}
			}
		}
	}
}
.different-address {
	h3 {
		label {
			display: inline-block;
			margin-right: 20px;
			font-size: 25px;
			margin-bottom: 10px;
			@include breakpoint (max-xxs_device) {
				font-size: 15px;
			}
		}
		input {
			height: inherit;
			line-height: normal;
			margin: 4px 0 0;
			position: relative;
			top: 0;
			width: auto;
		}
	}
}
.order-notes {
	.checkout-form-list-2 {
		margin-bottom: 0;
		@include breakpoint (max-md_device) {
			margin-bottom: 30px;
		}
	}
	textarea {
		background-color: transparent;
		border: 1px solid $border-color;
		height: 90px;
		padding: 15px;
		width: 100%;
	}
}
.create-account,
#ship-box-info {
	display: none;
}
.your-order {
	background: $bg-color;
    padding: 30px 40px 30px;
    @include breakpoint(max-xxs_device){
        padding: 30px 20px;
    }
	h3 {
		border-bottom: 1px solid $border-color;
		font-size: 25px;
		padding-bottom: 10px;
		text-transform: uppercase;
		width: 100%;
		@include breakpoint (max-xxs_device) {
			font-size: 18px;
		}
	}
	.your-order-table {
		table {
			background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
			border: medium none;
			width: 100%;
			thead {
				background-color: $bg-color;
				tr {
					th {
						border-top: medium none;
						font-weight: normal;
						text-align: center;
						text-transform: uppercase;
						vertical-align: middle;
						width: 250px;
					}
					th,
					td {
						border-bottom: 1px solid $border-color;
						border-right: medium none;
						font-size: 14px;
						padding: 15px 0;
						text-align: center;
					}
				}
			}
			.cart_item {
				&:hover {
					background: $bg-color;
				}
			}
			.order-total {
				td {
					border-bottom: medium none;
					span {
						font-size: 20px;
					}
				}
				th {
					border-bottom: medium none;
					font-size: 18px;
				}
			}
		}
	}
}
.payment-method {
	margin-top: 20px;
	.payment-accordion {
		.card {
			border: 0;
			border-radius: 0;
			background: $bg-color;
		}
		.card-header {
			padding: 0;
			margin-bottom: 0;
			background-color: $bg-color;
            border-bottom: 0;
            .panel-title{
                font-size: 18px;
                font-weight: 700;
            }
		}
		.card-body {
            padding: 10px;
            background: $white-color;
            p{
                margin-bottom: 0px;
            }
		}
		.panel.panel-default {
			margin-bottom: 20px;
		}
		.panel-title {
			font-size: 18px;
			cursor: pointer;
		}
	}
}
