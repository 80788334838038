/*----------------------------------------*/
/*    Shop Category
/*----------------------------------------*/
.cat-1 {
	width: 39%;
	flex: 0 0 39%;
    max-width: none;
    @include breakpoint(max-sm_device){
        width: 100%;
        flex: 0 0 100%;
    }
}

.cat-2 {
	width: 61%;
	flex: 0 0 61%;
	max-width: none;
    @include breakpoint(max-sm_device){
        width: 100%;
        flex: 0 0 100%;
    }
}

.cat-3 {
	width: 54%;
	flex: 0 0 54%;
	max-width: none;
    @include breakpoint(max-xxs_device){
        width: 100%;
        flex: 0 0 100%;
    }
}

.cat-4 {
	width: 46%;
	flex: 0 0 46%;
	max-width: none;
    @include breakpoint(max-xxs_device){
        width: 100%;
        flex: 0 0 100%;
    }
}

.cat-5 {
	width: 36%;
	flex: 0 0 36%;
	max-width: none;
    @include breakpoint(max-xxs_device){
        width: 100%;
        flex: 0 0 100%;
    }
}

.cat-6 {
	width: 64%;
	flex: 0 0 64%;
	max-width: none;
    @include breakpoint(max-xxs_device){
        width: 100%;
        flex: 0 0 100%;
    }
}

.categories-img{
    position: relative;
    > a{
        display: block;
        width: 100%;
        img{
            width: 100%;
        }
        &:hover{
            box-shadow: 0px 10px 30px 0px #d3d3d3;
        }
    }
    &:hover{
        .categories-content {
            background: $primary-color;
        }
    }
}

.categories-content {
	background: $white-color;
	left: 50%;
	margin: 0;
	margin-right: -50%;
	padding: 10px 15px;
	pointer-events: none;
	position: absolute;
	text-align: center;
	top: 50%;
	z-index: 9;
	transform: translate(-50%, -50%);
	transition: all .3s ease;
}
.categories-content{
    >h3 {
        color: #000;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 5px;
    }
}

.categories-content{
    >h4 {
        color: $primary-color;
        font-size: 11px;
        letter-spacing: 2px;
        text-transform: uppercase;
        margin-bottom: 0;
    }
}

.categories-img:hover .categories-content>h3,
.categories-img:hover .categories-content>h4 {
	color: $white-color;
}
